import React, { useRef, useState } from "react";
import "./Contact2.css";
import emailjs from "emailjs-com";

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_bdfl0ie", "template_ju36sma", form.current, "CLcHWAKSemVMd1_sU")
            .then(
                (result) => {
                    console.log(result.text);
                    setButtonText("Message Sent"); // Change button text
                    setTimeout(() => {
                        e.target.reset(); // Reset form after 3 seconds
                        setButtonText("Submit");
                    }, 3000);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className="contactform2">
            <div className="contact2-container">
                <div className="contact2-header contact2-container">
                    <h1>DO YOU NEED URGENT HELP?</h1>
                    <p>Our Mental Health and Addiction Support team is available 24/7.</p>
                </div>

                <div className="contact-form-content">
                    <div className="left-side">
                        <div className="address details">
                            <i className="fas fa-map-marker-alt"></i>
                            <div className="topic">Address</div>
                            <div className="text-one">13961 Mauve Dr.,</div>
                            <div className="text-two">Santa Ana, CA 92705</div>
                        </div>

                        <div className="phone details">
                            <i className="fas fa-phone-alt"></i>
                            <div className="topic">Phone</div>
                            <div className="text-one">
                                <a href="tel:3237920502">+1 (323) 419-5733</a>
                            </div>
                        </div>

                        <div className="email details">
                            <i className="fas fa-envelope"></i>
                            <div className="topic">Email</div>
                            <div className="text-one">info@rocklinrehab.com</div>
                        </div>
                    </div>

                    <div className="right-side">
                        <div className="topic-text">Send us a message</div>
                        <p className="topic-text-p">Please call our admission department if you have any questions.</p>

                        <form ref={form} onSubmit={sendEmail}>
                            <div className="input-box">
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder="Your Name" 
                                    pattern="[A-Za-z\s]+" 
                                    title="Only letters and spaces allowed" 
                                    required 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Your Email" 
                                    required 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="tel" 
                                    name="phone" 
                                    placeholder="Your Phone" 
                                    pattern="\d{10,15}" 
                                    title="Enter a valid phone number" 
                                    required 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="text" 
                                    name="subject" 
                                    placeholder="Subject" 
                                    required 
                                />
                            </div>
                            <div className="input-box message-box">
                                <textarea 
                                    name="message" 
                                    rows="4" 
                                    placeholder="How can we help?" 
                                    required
                                ></textarea>
                            </div>
                            <input className="contact-btn" type="submit" value={buttonText} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
