import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";

const Hero = () => {
    return (
<>        
<div className='hero'>
    <div className="hero-container">

        <div className="content">
                <h1>Rebuild, Recover, Reclaim Your Life</h1>
                <h2>ROCKLIN REHAB</h2>
                <p>Whether you or a loved one is seeking help, Rocklin Rehab is here to guide you every step of the way. Join us on the path to recovery and discover the stability and hope that awaits at Rocklin Rehab.
                </p>

                <div className='heroBtn'>

                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/contact'>
                    <div className='hero-btn-1'>
                    <button>Find Help Now</button>
                    </div>
                    </Link>
                    </LinkRoll>

                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/treatment'>
                    <div className='hero=btn-2'>
                    <button>Our Treatment</button>
                    </div>
                    </Link>
                    </LinkRoll>

                </div>


        </div>
    </div>
</div>
</>

    )
}

export default Hero